import { transformTime, getSetting } from 'components/vendor/perform/localization';
import { error as logError } from 'components/vendor/perform/core';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

const SEC_IN_MILLIS = 1000;
const REFRESH_DATA_INTERVAL_TIME = 60 * SEC_IN_MILLIS;
const TIME_TO_START_AUTOREFRESH = 30 * 60 * SEC_IN_MILLIS;
const ONE_DAY = 24 * 60 * 60 * SEC_IN_MILLIS;
const DISPLAY_MODE_PARTIAL = 'partial';
const FETCH_DATA_MODE_ALL = 0;
const FETCH_DATA_MODE_UPDATE = 1;

/**
 * Soccer Match Details Header module
 * @param {Object} context - context object
 * @param {Object} settings - module settings
 * @param {boolean} settings.initialized
 *  - true/false flag to determine if module should be initialized
 * @param {string} settings.url - URL for ajax request
 * @param {Object} settings.asynchRequestParams - params for async/ajax request
 */
export default function (context, settings) {
  const $context = $(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const eventNamespace = pubsubNamespace(context);
  const classWidget = 'p0c-soccer-match-details-header';
  const selectorWidget = `.${classWidget}`;
  const selectorWrapper = `${selectorWidget}__wrapper`;
  const selectorColumn = `${selectorWidget}__column`;
  const selectorColumnInfo = `${selectorColumn}--info`;
  const selectorInfo = `${selectorWidget}__info--in-column`;
  const classScoreTime = `${classWidget}__score-time`;
  const classScoreTimeHidden = `${classScoreTime}--hidden`;
  const selectorScoreTime = `.${classScoreTime}`;
  const asyncRequestParams = settings.asyncRequestParams;
  const matchId = asyncRequestParams.matchId;
  const selectorScore = `${selectorWidget}__score`;
  let dataWorker;
  let initialized = settings.initialized;
  let ajaxInstance;
  let autoRefreshInterval;
  let mode = FETCH_DATA_MODE_ALL;
  let roomsWaitingToJoin = {};
  const roomsJoined = {};

  /**
   * Gets match header details using AJAX
   */
  function getMatchHeaderDetails() {
    if (!initialized) {
      return;
    }

    if (ajaxInstance) {
      ajaxInstance.abort();
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context]);
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);

    ajaxInstance = $.ajax({
      url: settings.url,
      data: asyncRequestParams,
    })
      .done(response => {
        if (response.status === 'success') {
          const $contextContent = $(response.data.html);
          const $info = $context.find(selectorInfo);
          switch (mode) {
            case FETCH_DATA_MODE_ALL:
              $context.empty().append($contextContent);
              break;
            case FETCH_DATA_MODE_UPDATE:
              $context.find(selectorColumnInfo).html($contextContent);
              $context.find(selectorColumnInfo).append($info);
              break;
            default:
              break;
          }
          correctDatesAndTimes();
          stopAutoRefresh($contextContent);
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError(`Error: ${xhr}, ${status}, ${error}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
        }
      })
      .always(() => {
        ajaxInstance = null;
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  /**
   * Correct dates and times to local timezone
   */
  function correctDatesAndTimes() {
    const $timestamps = $context
      .find('[data-utc]')
      .filter('[data-dateformat]');

    $timestamps.each(function correctTimestamps() {
      transformTime($(this)).displayLocaleTime();
    });

    $context.find(selectorScoreTime).removeClass(classScoreTimeHidden);
  }

  /**
   * Checks if status is post game (canceled and played)
   * @param {string} status - status to be checked
   */
  function isPostGame(status) {
    return status === 'postGame';
  }

  /**
   * Checks status of new data and stops refreshing when status is appropriate
   * @param {jQuery} $contextContent - container which has "data-match-status" attribute
   */
  function stopAutoRefresh($contextContent) {
    const matchStatus = $contextContent.attr('data-match-status');
    if (isPostGame(matchStatus)) {
      clearInterval(autoRefreshInterval);

      if (dataWorker) {
        dataWorker.postMessage({
          action: 'stop',
        });
      }
    }
  }

  /**
   * Init connection with Middleware WebSocket
   * @param {number} startRefreshAt - date from which to fetch events list
   */
  function initSocket(startRefreshAt) {
    if (!getSetting('common.middleware.socket.enabled') || dataWorker) {
      return;
    }

    dataWorker = new Worker('widgets/livescore/data.worker', {
      name: 'livescoreDataWorker',
      type: 'module',
    });

    dataWorker.addEventListener('message', (message) => {
      const newData = message.data;
      pubsub.publish('socket/data', [newData]);
      if (newData.state === 'data' && newData.data[matchId]) {
        const match = newData.data[matchId];
        ['home', 'away'].forEach(side => {
          const upperCase = side.charAt(0).toUpperCase() + side.substr(1);
          $context.find(`${selectorScore}-${side}`)
            .text(match[`score${upperCase}`]);
        });
      }
    });

    dataWorker.postMessage({
      action: 'stateOptions',
      options: {
        requireImmutables: false,
        filterKeys: false,
      },
    });

    dataWorker.postMessage({
      action: 'start',
      providers: {
        socket: {
          tokenUrl: getSetting('common.middleware.api.tokenUrl'),
          translations: settings.translations,
          throttle: 1000,
          autoEventsList: true,
          eventsListStartDate: startRefreshAt,
          socketUrl: settings.socketUrl,
          socketNamespace: 'mksh',
        },
      },
    });

    pubsub.subscribe('socket/join-room', roomName => {
      roomsJoined[roomName] = 1;
      dataWorker.postMessage({
        action: 'joinRoom',
        options: {
          roomName,
        },
      });
    });

    pubsub.publish('socket/starting', []);
  }

  function joinRooms() {
    if (!initialized) {
      return;
    }

    for (const roomName in roomsWaitingToJoin) {
      pubsub.publish('socket/join-room', [roomName]);
    }
    roomsWaitingToJoin = {};
  }

  const socketStartingSubscription = pubsub.subscribe('socket/starting', eventContext => {
    initialized = true;
    joinRooms();
    pubsub.unsubscribe(socketStartingSubscription);
  });

  /**
   * Start auto refresh of match header
   */
  function startUpdate() {
    const $wrapper = $context.find(selectorWrapper);
    const matchStatus = $wrapper.attr('data-match-status');
    asyncRequestParams.displayMode = DISPLAY_MODE_PARTIAL;

    if (isPostGame(matchStatus)) {
      return;
    }
    roomsWaitingToJoin[`mksh:soccer-${settings.asyncRequestParams.matchId}`] = 1;
    const matchStartTimestamp = $wrapper.attr('data-utc') * SEC_IN_MILLIS;
    const startRefreshAt = matchStartTimestamp - TIME_TO_START_AUTOREFRESH;
    const timeOut = Math.min(ONE_DAY, Math.max(0, startRefreshAt - (new Date()).getTime()));

    if (!autoRefreshInterval) {
      mode = FETCH_DATA_MODE_UPDATE;
      setTimeout(() => {
        autoRefreshInterval = setInterval(getMatchHeaderDetails, REFRESH_DATA_INTERVAL_TIME);
        initSocket(startRefreshAt);
      }, timeOut);
    }
  }

  if (!initialized) {
    const initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getMatchHeaderDetails();
      pubsub.unsubscribe(initSubscription);
    });

    const completeSubscription = pubsub.subscribe(
      `${eventNamespace}/ajax/complete`,
      eventContext => {
        if (context !== eventContext) {
          return;
        }

        startUpdate();
        pubsub.unsubscribe(completeSubscription);
      }
    );
  } else {
    correctDatesAndTimes();
    startUpdate();
  }
}
