// @ts-ignore
import { error as logError } from 'components/vendor/perform/core';
// @ts-ignore
import { onRAF } from 'components/vendor/perform/utils';
import { GameStatsData , GameStatsSettings } from './types';

const UPDATE_DELAY_LIVE = 90000; // 90 seconds
const UPDATE_DELAY_PRE = 300000; // 5 minutes

const STATUS_PLAYING = 'Playing';
const STATUS_PLAYED = 'Played';
const STATUS_FIXTURE = 'Fixture';

export default class GameStats {
  private readonly context: HTMLElement;
  private readonly settings: GameStatsSettings;
  private readonly widgetSelector: string;
  private readonly possesionChart: HTMLElement;
  private readonly shotsChart: HTMLElement;
  private readonly passesChart: HTMLElement;
  private readonly size: number = 70;
  private readonly unit: number;
  private autoUpdateTimeout: number;
  private autoUpdateDelay: number;
  private widgetClass: string;
  private classWidgetHidden: string;
  private widgetStatus: string;

  constructor(context: HTMLElement, settings: GameStatsSettings & GameStatsData) {
    this.context = context;
    this.settings = settings;
    this.widgetClass = 'widget-game-stats';
    this.widgetSelector = `.${this.widgetClass}`;
    this.classWidgetHidden = `${this.widgetClass}--hidden`;
    this.possesionChart = this.context.querySelector(`${this.widgetSelector}__stats-possession`);
    this.shotsChart = this.context.querySelector(`${this.widgetSelector}__stats-shots`);
    this.passesChart = this.context.querySelector(`${this.widgetSelector}__stats-passes`);
    this.unit = (Math.PI * 2) / 100;
    this.autoUpdateTimeout = null;
    this.widgetStatus = this.settings.status;
  }

  /**
   * Set update delay relying on live status
   */
  private setUpdateDelay(status: string, startTime: number) {
    if (status === STATUS_PLAYED) {
      this.context.classList.remove(this.classWidgetHidden);
      this.autoUpdateTimeout = 0;
    } else if (status === STATUS_PLAYING || startTime <= Date.now() + UPDATE_DELAY_PRE) {
      this.autoUpdateDelay = UPDATE_DELAY_LIVE;
      this.context.classList.remove(this.classWidgetHidden);
    } else {
      this.autoUpdateDelay = UPDATE_DELAY_PRE;
    }
  }

  /**
   * Start widget auto update
   */
  private scheduleUpdate() {
    this.cancelScheduledUpdate();
    this.autoUpdateTimeout = window.setTimeout(this.update.bind(this), this.autoUpdateDelay);
  }

  /**
   * Cancel scheduled update (if any)
   */
  private cancelScheduledUpdate() {
    if (this.autoUpdateTimeout) {
      clearTimeout(this.autoUpdateTimeout);
    }
  }

  private update() {
    fetch(this.settings.url)
      .then(response => response.json())
      .then(({ data }) => {
        if (data.status === STATUS_PLAYED) {
          this.cancelScheduledUpdate();
          return;
        }

        if (this.widgetStatus !== data.status
          && this.widgetStatus === STATUS_FIXTURE) {
          this.context.classList.remove(this.classWidgetHidden);
        }

        onRAF(() => {
          this.updatePossesionChart(data);
          this.updateShots(data);
          this.updatePasses(data);
        });

        this.scheduleUpdate();
      })
      .catch((error) => {
        logError('widget:game-stats error: ', error);
      });
  }

  private updatePossesionChart(data: GameStatsData) {
    const dataPercent = data.home.possesionPercentage || 50;
    const prc = 100 - Math.round(dataPercent);
    const mainWedge = this.possesionChart.querySelector(`${this.widgetSelector}__wedge`);
    const awayLabel = this.possesionChart.querySelector(`${this.widgetSelector}__lbl--away`);
    const homeLabel = this.possesionChart.querySelector(`${this.widgetSelector}__lbl--home`);
    const blankWedge = this.possesionChart.querySelector(`${this.widgetSelector}__wedge-blank`);

    this.changeWedge(blankWedge, 0, prc + 1);
    this.changeWedge(mainWedge, 1, prc);

    awayLabel.innerHTML = `<span>%</span>${prc}`;
    homeLabel.innerHTML = `<span>%</span>${100 - prc}`;
  }

  private changeWedge(wedge: Element, startAngle: number, percentage: number) {
    const endAngle = (percentage * this.unit) - 0.001;
    const modifiedStartAngle = startAngle * this.unit;
    let x1;
    let y1;
    let x2;
    let y2;
    let big = 0;
    let d = null;

    x1 = (this.size / 2) + ((this.size / 2) * Math.sin(modifiedStartAngle));
    y1 = (this.size / 2) - ((this.size / 2) * Math.cos(modifiedStartAngle));
    x2 = (this.size / 2) + ((this.size / 2) * Math.sin(endAngle));
    y2 = (this.size / 2) - ((this.size / 2) * Math.cos(endAngle));

    if (endAngle - modifiedStartAngle > Math.PI) {
      big = 1;
    }

    d = `M 35,35 L ${x1},${y1} A 35,35 0 ${big} 1 ${x2},${y2} Z`;

    wedge.setAttribute('d', d);
  }

  private updateShots(
    { home , away }: { home : GameStatsData['home'], away: GameStatsData['away']},
  ) {
    Array.prototype.filter.call(
      this.shotsChart.children,
      el => el.classList.contains(`${this.widgetClass}__part-home`),
    )
      .forEach((el) => {
        el.querySelector(`${this.widgetSelector}__value-home`)
          .innerText = home.shotsOffTarget || 0;
      });

    Array.prototype.filter.call(
      this.shotsChart.children,
      el => el.classList.contains(`${this.widgetClass}__part-away`),
    )
      .forEach((el) => {
        el.querySelector(`${this.widgetSelector}__value-away`)
          .innerText = away.shotsOffTarget || 0;
      });

    this.shotsChart
      .querySelector(`${this.widgetSelector}__gate ${this.widgetSelector}__value-home`)
      .innerText = home.shotsOnTarget || 0;
    this.shotsChart
      .querySelector(`${this.widgetSelector}__gate ${this.widgetSelector}__value-away`)
      .innerText = away.shotsOnTarget || 0;
  }

  private updatePasses(data: GameStatsData) {
    const homePasses = data.home.totalPasses || 0;
    const awayPasses = data.away.totalPasses || 0;
    const allPasses = homePasses + awayPasses;
    let ratio = 50;

    this.passesChart
      .querySelector(`${this.widgetSelector}__value-home`)
      .textContent = String(homePasses);
    this.passesChart.querySelector(`${this.widgetSelector}__value-away`)
      .textContent = String(awayPasses);

    if (allPasses) {
      ratio = (homePasses / allPasses) * 100;
    }
    this.passesChart
      .querySelector(`${this.widgetSelector}__bar span`)
      .style.width = String(`${ratio}%`);
  }

  public init() {
    this.updatePossesionChart(<GameStatsData>{
      home: {
        possesionPercentage:
            Number(this.possesionChart.getAttribute('data-stat')) || 0,
      },
    });

    this.setUpdateDelay(this.settings.status, this.settings.startTime);
    this.scheduleUpdate();
  }
}
