import { changePageData } from 'components/vendor/perform/localization';
import moment from 'moment';

require('pages/match/base.scss');
require('pages/match/ga-events');

require('widgets/match-detail-submenu/style.scss');
require('widgets/article/latest-news');
require('widgets/nesine/most-played-coupons');

const selectorPage = '.page-match';

const $context = $(selectorPage);
const settings = $context.data('settings');

/**
 * Localizes dates in title, desctiption and headline
 */
function correctDates() {
  const localTime = moment(settings.match.startTime.utc);
  const utcTime = moment(settings.match.startTime.utc).utc();
  const DATE_PLACEHOLDER_NAME = ':date';

  if (localTime.format('D') === utcTime.format('D')) {
    return;
  }

  const localizedTime = localTime.format('DD.MM.YYYY');

  changePageData({
    title: settings.translations.title
      .replace(DATE_PLACEHOLDER_NAME, localizedTime),
    description: settings.translations.description
      .replace(DATE_PLACEHOLDER_NAME, localizedTime),
  });

  $context.find('h1').html(
    settings.translations.headline
      .replace(DATE_PLACEHOLDER_NAME, localizedTime)
  );
}

correctDates();
