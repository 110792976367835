import { applyEventsMap } from 'components/ga';

const selectorMatchMenuItem = '.widget-match-detail-submenu__icon';
const selectorVoteButton = '.widget-user-polls .gig-button';

const gaEventMapping = {
  [selectorMatchMenuItem]: {
    category: 'MatchDetailMenu',
  },
  [selectorVoteButton]: {
    category: 'PollResult',
    action: 'PollVote',
    label: 'Anket',
  },
};

applyEventsMap(gaEventMapping, (selector, $item, eventData) => {
  if (selector === selectorMatchMenuItem) {
    switch ($item.data('itemId')) {
      case 'match':
        eventData.action = 'SummaryClick';
        eventData.label = 'Ozet';
        break;
      case 'iddaa':
        eventData.action = 'IddaaClick';
        eventData.label = 'iddaa';
        break;
      case 'form':
        eventData.action = 'FormClick';
        eventData.label = 'form';
        break;
      case 'stats':
        eventData.action = 'StatsClick';
        eventData.label = 'istatistik';
        break;
      case 'forum':
        eventData.action = 'LiveClick';
        eventData.label = 'canli';
        break;
      default:
        return false;
    }
  }

  return true;
});
