import { onRAF, pubsubNamespace } from 'components/vendor/perform/utils';
import { breakpoint } from 'components/vendor/perform/responsive';
import globalPubsub from 'pubsub.js';

import 'widgets/team-switch/style.scss';

/**
 * Team switch widget module
 * @param {Object} context - context object
 */
export default function (context) {
  const $context = $(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const eventNamespace = pubsubNamespace(context);
  const classWidgetTeamSwitch = 'widget-team-switch';
  const selectorWidget = `.${classWidgetTeamSwitch}`;
  const selectorTabsItem = `${selectorWidget}__tabs-item`;
  const selectorContent = `${selectorWidget}__content`;
  const classContentSlides = `${classWidgetTeamSwitch}__content-slides`;
  const selectorContentSlides = `${selectorWidget}__content-slides`;
  const classContentSlidesTranslated = `${classContentSlides}--translated`;
  const classTabsItemActive = `${classWidgetTeamSwitch}__tabs-item--active`;
  const selectorTabsItemActive = `.${classTabsItemActive}`;
  const $tabs = $context.find(selectorTabsItem);
  const $contentWrap = $context.find(selectorContent);
  const $contentSlides = $contentWrap.find(selectorContentSlides);

  let activeTabId = $tabs.filter(selectorTabsItemActive).data('tab');

  /**
   * Activate tab
   * @param {string} newTabId - tab id to activate
   * @param {boolean} [force=false] - force tab activation even if tab is active
   * @private
   */
  function activateTab(newTabId, force) {
    force = !!force;

    if (newTabId === activeTabId && !force) {
      return;
    }

    activeTabId = newTabId;
    onRAF(() => {
      pubsub.publish(`${eventNamespace}/before-change`, [context, activeTabId]);

      $tabs.removeClass(classTabsItemActive);
      $tabs.filter(`[data-tab="${activeTabId}"]`).addClass(classTabsItemActive);

      if (activeTabId !== 'home') {
        $contentSlides.addClass(classContentSlidesTranslated);
      } else {
        $contentSlides.removeClass(classContentSlidesTranslated);
      }

      pubsub.publish(`${eventNamespace}/change`, [context, activeTabId]);
    });
  }

  /**
   * Initialize widget
   * @private
   */
  function init() {
    $tabs.on('click', evt => {
      const $tab = $(evt.target).closest(selectorTabsItem);
      const tabId = $tab.data('tab');

      activateTab(tabId);
    });

    if ($context.find('.starting-eleven-container').length > 0) {
      initStartingEleven();
    }
  }

  /**
   * Init starting eleven scripts
   */
  function initStartingEleven() {
    const classTeamSwitch = 'widget-team-switch';
    const classMatchStats = 'widget-match-stats';
    const classMatchStatsStartingEleven = `${classMatchStats}__starting-eleven`;
    const classMatchStatsStartingElevenHidden = `${classMatchStatsStartingEleven}--hidden`;
    const selectorMatchStatsStartingEleven = `.${classMatchStatsStartingEleven}`;
    const $startingEleven = $(selectorMatchStatsStartingEleven);
    const classWidgetStartingEleven = 'widget-starting-eleven';
    const classTitleIcon = `${classWidgetStartingEleven}__title-icon`;
    const selectorTitleIcon = `.${classTitleIcon}`;
    const classTitleIconActive = `${classTitleIcon}--active`;
    const classDropdown = `${classTeamSwitch}__dropdown`;
    const classDropdownContainer = `${classDropdown}-container`;
    const classDropdownHide = `${classDropdown}--hide`;
    const selectorDropdown = `.${classTeamSwitch}__dropdown`;
    const classContent = `${classWidgetStartingEleven}__content`;
    const selectorContentWidgetStartingEleven = `.${classContent}`;
    const classContentHidden = `${classContent}--hidden`;
    const $dropdown = $(selectorDropdown);
    const $content = $context.find(selectorContentWidgetStartingEleven);
    const classPreviousSquad = 'widget-previous-squad';
    const classPreviousSquadTeamContainer = `${classPreviousSquad}__team-container`;
    const classPreviousSquadTeamContainerHome = `${classPreviousSquadTeamContainer}--home`;
    const classPreviousSquadTeamContainerAway = `${classPreviousSquadTeamContainer}--away`;
    const classPreviousSquadNoDataCotainer = `${classPreviousSquadTeamContainer}--no-data`;
    const $selectorPreviousSquad = $context.find(`.${classPreviousSquad}`);

    const dropdownContentShow = $(selectorDropdown).data('show-starting');
    const dropdownContentHide = $(selectorDropdown).data('hide-starting');

    const isContainerAwayLimitedData = $context
      .find(`.${classPreviousSquadTeamContainer}.${classPreviousSquadTeamContainerAway}`)
      .hasClass(`${classPreviousSquadNoDataCotainer}`);
    const isContainerHomeLimitedData = $context
      .find(`.${classPreviousSquadTeamContainer}.${classPreviousSquadTeamContainerHome}`)
      .hasClass(`${classPreviousSquadNoDataCotainer}`);

    /**
     * Change height of previous squad container
     */
    function changeContainerHeight() {
      if (!(isContainerAwayLimitedData || isContainerHomeLimitedData) || !breakpoint('to', '740')) {
        if (breakpoint('from', '740')) {
          $selectorPreviousSquad.removeClass(`${classPreviousSquad}--no-data`);
        }
        return;
      }

      if ((isContainerAwayLimitedData && activeTabId === 'away') ||
        (isContainerHomeLimitedData && activeTabId === 'home')) {
        $selectorPreviousSquad.addClass(`${classPreviousSquad}--no-data`);
      } else {
        $selectorPreviousSquad.removeClass(`${classPreviousSquad}--no-data`);
      }
    }

    $context.on('click', selectorTitleIcon, function changeIconActiveState() {
      $content.toggleClass(classContentHidden);
      $(this).toggleClass(classTitleIconActive);
    });

    $context
      .find(selectorDropdown)
      .on('click', () => {
        $startingEleven
          .toggleClass(classMatchStatsStartingElevenHidden);

        if (!$startingEleven
          .hasClass(classMatchStatsStartingElevenHidden)) {
          $dropdown.text(dropdownContentHide);
        } else {
          $dropdown.text(dropdownContentShow);
        }

        $dropdown
          .toggleClass(classDropdownHide);
      });

    $context
      .find('.widget-team-switch--mobile opta-widget')
      .attr('orientation', 'vertical');

    pubsub.subscribe(`${eventNamespace}/show-match-stats`,
      () => {
        $startingEleven
          .removeClass(classMatchStatsStartingElevenHidden)
          .css('transition', 'none');
      }
    );

    pubsub.subscribe(`${eventNamespace}/display-dropdown`,
      () => {
        $context
          .find(`.${classDropdownContainer}`)
          .addClass(`${classDropdownContainer}--display`);
      }
    );

    pubsub.subscribe(`${eventNamespace}/before-change`, (eventContext) => {
      if (eventContext !== context) {
        return;
      }

      changeContainerHeight();
    });

    $(window)
      .on('breakpointchange', changeContainerHeight);

    changeContainerHeight();
  }

  init();
}
