import globalPubsub from 'pubsub.js';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import { loadStyle, loadScript } from 'components/opta-widget';
import { info } from 'components/vendor/perform/core';
import { show as showLoader, hide as hideLoader } from 'components/loader';
import { football } from 'components/opta-widget/sports';


const instanceCounters = {};
const defaultSettings = {
  widgetId: 'opta-widget',
  onDrawn: null,
  onError: null,
  loadStyle: true,
  sport: football,
  params: {},
};

/**
 * Basic opta widget wrapper
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings) {
  const $context = $(context);
  const $widget = $context.find('opta-widget');
  const eventNamespace = pubsubNamespace(context);
  const pubsub = $context.data('pubsub') || globalPubsub;

  settings = $.extend({}, defaultSettings, settings);

  if (!instanceCounters[settings.widgetId]) {
    instanceCounters[settings.widgetId] = 0;
  }

  const instanceIndex = ++instanceCounters[settings.widgetId];
  const widgetId = `${settings.widgetId}-${instanceIndex}`;

  /**
   * Call error callback
   * @param {object|null} [data=null] - widget data
   */
  function callOnError(data = null) {
    info(`Opta ${widgetId} widget error: ${data ? data.error.message : 'unknown'}`);
    hideLoader(context);
    pubsub.publish(`${eventNamespace}/opta/error`, [context, widgetId, data]);

    if (typeof settings.onError === 'function') {
      settings.onError(data, context, widgetId);
    }
  }

  /**
   * initialize opta widget
   */
  function initOptaWidget() {
    $widget.attr('widget_id', widgetId).removeAttr('load');
    pubsub.publish(`${eventNamespace}/opta/init`, [context, widgetId]);

    window.Opta.events.subscribe('widget.drawn', (data) => {
      if (data.widget.id === widgetId) {
        hideLoader(context);
        pubsub.publish(`${eventNamespace}/opta/drawn`, [context, widgetId, data]);

        if (typeof settings.onDraw === 'function') {
          settings.onDraw(data, context, widgetId);
        }
      }
    });

    window.Opta.events.subscribe('widget.error', (data) => {
      if (data.widget.id === widgetId) {
        callOnError(data);
      }
    });

    window.Opta.events.subscribe('application.error', (error) => {
      if (error.error && error.error.attr && error.error.attr.widget_id === widgetId) {
        callOnError(error);
      }
    });

    try {
      window.Opta.start();
    } catch (e) {
      callOnError();
    }
  }

  /**
   * Initialize widget
   * @private
   */
  function init() {
    showLoader(context);

    if (settings.loadStyle) {
      $.when(
        loadStyle(settings.sport),
        loadScript(settings.params)
      ).done(initOptaWidget);
    } else {
      loadScript(settings.params).done(initOptaWidget);
    }
  }

  init();
}
