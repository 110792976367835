import start from 'assets/js/main';
import { forEachElement } from 'components/utils/script';

require('pages/match/soccer/base');
require('pages/match/soccer/match/style.scss');
require('pages/match/soccer/match/ga-events');

require('p0compLocal/match/officials');
require('p0compLocal/match/officials/style.scss');

require('p0compLocal/competition/tables');
require('p0compLocal/competition/tables/style.scss');

require('widgets/team-switch');
require('widgets/section-title-bar/style.scss');
require('widgets/starting-eleven');
require('widgets/match-stats');
require('widgets/match-summary');
require('widgets/match-tv-listing');
require('widgets/key-events');
require('widgets/live-action');
require('widgets/previous-squad');
require('widgets/sidelined-players');
require('widgets/game-stats');

const classPage = 'page-match';
const selectorPage = `.${classPage}`;
const classWidget = 'widget-team-switch';
const selectorPreviousSquadContainer = '.widget-previous-squad__container';
const selectorWidget = `.${classWidget}`;
const classWidgetHidden = `${classWidget}--hidden`;
const classMatchStats = 'widget-match-stats__container';
const selectorMatchStats = `.${classMatchStats}`;
const selectorContentSlides = `${selectorWidget}__content-slides`;
const classSidelinedPlayersEmpty = 'widget-sidelined-players--empty';
const selectorSidelinedPlayersEmpty = `.${classSidelinedPlayersEmpty}`;
const selectorHideMatchStatsWidget = 'widget-match-stats__widget--hide';

const context = document.querySelector(selectorPage);
const $context = $(selectorPage);

/**
 * Display widget depends on contains data
 * @param {string} selector - widget selector
 */
function displayWidget(selector) {
  const emptyWidgets = context.querySelectorAll(selector);
  const selectorSidelinedPlayer = `${selectorContentSlides}--sidelined-players`;
  const sideLinedPlayerWidget = context.querySelector(selectorSidelinedPlayer);
  const classWidgetTeamSwitch = 'widget-team-switch';
  const classContentSlides = `${classWidgetTeamSwitch}__content-slides`;

  if (emptyWidgets.length) {
    forEachElement(emptyWidgets, widget => {
      widget.parentNode.classList.add(`${selectorHideMatchStatsWidget}`);
      return;
    });
  }

  if (emptyWidgets.length !== 2) {
    sideLinedPlayerWidget.classList.add(`${classContentSlides}--display`);
  }
}

/**
 * Check if context contains data
 * @returns {number} Number of elements
 */
function containsData() {
  return ($context.find(selectorSidelinedPlayersEmpty).length !== 2)
  || $context.find(selectorPreviousSquadContainer).length
  || $context.find(selectorMatchStats).length;
}

/**
 * If data dont exist hide team switch
 */
function init() {
  if (!containsData()) {
    $context.find(selectorWidget).addClass(classWidgetHidden);
  }
}

displayWidget(selectorSidelinedPlayersEmpty);

init();

start();
