import { error as logError } from 'components/vendor/perform/core';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import globalPubsub from 'pubsub.js';

/**
 * Match officials module
 * @param {Object} context - context object
 * @param {Object} settings - module settings
 */
export default function (context, settings) {
  const $context = $(context);
  const eventNamespace = pubsubNamespace(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  let initialized = !!settings.initialized;
  let ajaxInstance;

  /**
   * Get data for officials using AJAX
   */
  function getOfficialsData() {
    if (!initialized) {
      return;
    }

    if (ajaxInstance) {
      ajaxInstance.abort();
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context]);
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);

    ajaxInstance = $.ajax({
      url: settings.url,
      data: settings.asyncRequestParams,
    })
      .done(response => {
        if (response.status === 'success') {
          $context.html(response.data.html);
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        logError(`Error: ${xhr}, ${status}, ${error}`);
        pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
      })
      .always(() => {
        ajaxInstance = null;
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  if (!initialized) {
    const initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getOfficialsData();
      pubsub.unsubscribe(initSubscription);
    });
  }
}
