import 'widgets/match-summary/style.scss';
import { error as logError } from 'components/vendor/perform/core';
import globalPubsub from 'pubsub.js';
import { pubsubNamespace } from 'components/vendor/perform/utils';

const SEC_IN_MILLIS = 1000;
const REFRESH_DATA_INTERVAL_TIME = 60 * SEC_IN_MILLIS;
const TIME_TO_START_AUTOREFRESH = 30 * 60 * SEC_IN_MILLIS;

/**
 * Match summary widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings) {
  const $context = $(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const eventNamespace = pubsubNamespace(context);
  const asyncRequestParams = settings.asyncRequestParams;
  const classWidget = 'widget-match-summary';
  const selectorWidget = `.${classWidget}`;
  const selectorWrapper = `${selectorWidget}__wrapper`;
  let initialized = settings.initialized;
  let ajaxInstance;
  let autoRefreshInterval;

  /**
   * Gets match summary using AJAX
   */
  function getMatchSummary() {
    if (!initialized) {
      return;
    }

    if (ajaxInstance) {
      ajaxInstance.abort();
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context]);
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);

    ajaxInstance = $.ajax({
      url: settings.url,
      data: asyncRequestParams,
    })
      .done(response => {
        if (response.status === 'success') {
          const $contextContent = $(response.data.html);
          $context.empty().append($contextContent);
          stopAutoRefresh($contextContent);
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError(`Error: ${xhr}, ${status}, ${error}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
        }
      })
      .always(() => {
        ajaxInstance = null;
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  /**
   * Checks if status is post game (canceled and played)
   * @param {string} status - status to be checked
   */
  function isPostGame(status) {
    return status === 'postGame';
  }

  /**
   * Checks status of new data and stops refreshing when status is appropriate
   * @param {jQuery} $contextContent - container which has "data-match-status" attribute
   */
  function stopAutoRefresh($contextContent) {
    const matchStatus = $contextContent.attr('data-match-status');
    if (isPostGame(matchStatus)) {
      clearInterval(autoRefreshInterval);
    }
  }

  /**
   * Start auto refresh of match summary
   */
  function startUpdate() {
    const $wrapper = $context.find(selectorWrapper);
    const matchStatus = $wrapper.attr('data-match-status');
    if (isPostGame(matchStatus)) {
      return;
    }

    const matchStartTimestamp = $wrapper.attr('data-utc') * SEC_IN_MILLIS;
    const startRefreshAt = matchStartTimestamp - TIME_TO_START_AUTOREFRESH;
    const timeOut = startRefreshAt - (new Date()).getTime();
    if (!autoRefreshInterval) {
      if (timeOut > 0) {
        setTimeout(() => {
          autoRefreshInterval = setInterval(getMatchSummary, REFRESH_DATA_INTERVAL_TIME);
        }, timeOut);
      } else {
        autoRefreshInterval = setInterval(getMatchSummary, REFRESH_DATA_INTERVAL_TIME);
      }
    }
  }

  if (!initialized) {
    const initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getMatchSummary();
      pubsub.unsubscribe(initSubscription);
    });

    const completeSubscription = pubsub.subscribe(
      `${eventNamespace}/ajax/complete`,
      eventContext => {
        if (context !== eventContext) {
          return;
        }

        startUpdate();
        pubsub.unsubscribe(completeSubscription);
      }
    );
  } else {
    startUpdate();
  }
}
