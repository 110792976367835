import { applyEventsMap } from 'components/ga';

const selectorStartingElevenButton = '.widget-starting-eleven__title-icon--active';
const selectorOptaPlayerLink = '.Opta-PlayerLink';
const selectorOptaManagerLink = '.Opta-Manager a';
const selectorOfficialLink = '.p0c-match-officials__official-link';
const selectorSuspenDetail = '.widget-sidelined-players__expander--clicked';

const gaEventMapping = {
  [selectorStartingElevenButton]: {
    category: 'TeamFormartion',
    action: 'FormationClick',
    label: 'Dizilim',
  },
  [selectorOptaPlayerLink]: {
    category: 'Players',
    action: 'PlayerClick',
    label: 'Oyuncu',
  },
  [selectorOptaManagerLink]: {
    category: 'Managers',
    action: 'ManagerClick',
    label: 'Tdirektor',
  },
  [selectorOfficialLink]: {
    category: 'Referee',
    action: 'RefClick',
    label: 'Hakem',
  },
  [selectorSuspenDetail]: {
    category: 'Suspension Injury Detail',
    action: 'SuspenDetailClick',
    label: 'Sakat-Cezali',
  },
};

applyEventsMap(gaEventMapping);
