import 'widgets/key-events/style.scss';
import { error as logError } from 'components/vendor/perform/core';
import globalPubsub from 'pubsub.js';
import { pubsubNamespace } from 'components/vendor/perform/utils';
import { html, render } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { classMap } from 'lit-html/directives/class-map';

require('@webcomponents/template');

const SEC_IN_MILLIS = 1000;
const REFRESH_DATA_INTERVAL_TIME = 60 * SEC_IN_MILLIS;
const TIME_TO_START_AUTOREFRESH = 30 * 60 * SEC_IN_MILLIS;
const ONE_DAY = 24 * 60 * 60 * SEC_IN_MILLIS;

/**
 * Match summary widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings) {
  const $context = $(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const eventNamespace = pubsubNamespace(context);
  const asyncRequestParams = settings.asyncRequestParams;
  const widgetName = 'widget-key-events';
  let initialized = settings.initialized;
  let ajaxInstance;
  let updateTimeout;

  const getModifiersClassMap = (event, element) => {
    const classes = {
      [`${widgetName}__${element}`]: true,
      [`${widgetName}__${element}--${event.type}`]: true,
    };

    if (event.subType && event.subType !== event.type) {
      classes[`${widgetName}__${element}--${event.subType}`] = true;
    }

    return classes;
  };

  const eventImage = event => {
    if (event.type !== 'penalty') {
      const classes = getModifiersClassMap(event, 'event-image');

      return html`<div class=${classMap(classes)}></div>`;
    }

    return '';
  };

  const eventTime = event => {
    if (event.timeMin) {
      return html`
        <div class="${widgetName}__time">
          ${event.timeMin.split(' ').join('')}${settings.translations.minutesIndicator}
        </div>
      `;
    }

    const classes = getModifiersClassMap(event, 'time');

    return html`
      <div class=${classMap(classes)}></div>
    `;
  };

  const playerBox = (event, config = {}) => {
    const { type, modifier, textBefore } = Object.assign({
      type: 'player',
      modifier: '',
      textBefore: '',
    }, config);

    if (!event[`${type}Name`]) {
      return '';
    }

    const classes = {
      [`${widgetName}__player`]: true,
    };

    if (modifier) {
      classes[`${widgetName}__player--${modifier}`] = true;
    }

    if (event[`${type}Url`]) {
      classes[`${widgetName}__player--link`] = true;

      return html`
        <a href="${event[`${type}Url`]}" class=${classMap(classes)} data-jsblank="true">
          ${textBefore}
          ${event[`${type}Name`]}
        </a>
      `;
    }

    return html`
      <span class=${classMap(classes)}>
        ${textBefore}
        ${event[`${type}Name`]}
      </span>
    `;
  };

  const goalEvent = event => {
    switch (event.subType) {
      case 'own-goal':
        return html`
          <div class="${widgetName}__label">${settings.translations.og}</div>
          ${playerBox(event)}
        `;
      default:
        return html`<div class="${widgetName}__goal">
          ${playerBox(event)}${playerBox(event, {
            type: 'assistPlayer',
            modifier: 'assist',
          })}</div>`;
    }
  };

  /**
   * Get data for single match with properly event
   * @param {Object} event - event data
   * @returns {*} - lit html template or empty string
   */
  const eventContent = event => {
    switch (event.type) {
      case 'card':
        return html`${playerBox(event)}`;
      case 'goal':
        return html`
          <div class="${widgetName}__score">
            ${settings.translations.openBracket}${event.score}${settings.translations.closeBracket}
          </div>
          ${goalEvent(event)}
        `;
      case 'penalty':
        return html`
          <div class="${widgetName}__score">
            ${settings.translations.openBracket}${event.score}${settings.translations.closeBracket}
          </div>
          ${playerBox(event)}
        `;
      case 'penalty-missed':
        return html`
          <div class="${widgetName}__score">
            ${settings.translations.openBracket}${event.score}${settings.translations.closeBracket}
          </div>
          <div class="${widgetName}__label">${settings.translations[event.subType]}</div>
          ${playerBox(event)}
        `;
      case 'substitute':
        return html`
          <div class="${widgetName}__substitute">
            <div class="${widgetName}__substitute-players">
              ${playerBox(event, { modifier: 'in' })}
              ${playerBox(event, { type: 'playerOut', modifier: 'out' })}
            </div>
          </div>
        `;
      default:
        return '';
    }
  };

  const eventRow = (event, oddEven) => html`
    <div class="${widgetName}__row 
        ${widgetName}__row--${event.position}
        ${widgetName}__row--${oddEven}
      " 
      data-event-type="${event.type}${event.subType ? `-${event.subType}` : ''}"
    >
      ${eventTime(event)}
      <div class="${widgetName}__event">
        ${eventImage(event)}
        ${eventContent(event)}
      </div>
    </div>
  `;

  const periodHeader = label => html`
    <div class="${widgetName}__period-header">${label}</div>
  `;

  const renderList = ({ keyEvents, finishedPeriodIds }) => {
    if (!keyEvents.length) {
      return;
    }

    const rows = [];
    let counter = 0;
    let lastScore = '0-0';

    /**
     * Add event row
     * @param {Object} event - event data
     */
    function addEventRow(event) {
      if (event.score) {
        lastScore = event.score;
      } else {
        event.score = lastScore;
      }

      rows.push({
        id: rows.length,
        template: html`${eventRow(event, counter++ % 2 === 0 ? 'even' : 'odd')}`,
      });
    }

    finishedPeriodIds.forEach(periodId => {
      keyEvents
        .filter(event => event.periodId === periodId)
        .forEach(addEventRow);

      const label = settings.translations[`period${periodId}`];

      if (label) {
        counter = 0;
        rows.push({
          id: rows.length,
          template: html`${periodHeader(`${label} ${lastScore}`)}`,
        });
      }
    });

    counter = 0;
    keyEvents
      .filter(event => finishedPeriodIds.indexOf(event.periodId) === -1)
      .forEach(addEventRow);

    context.classList.remove(`${widgetName}--empty`);
    render(html`
      <div class="${widgetName}__header">${settings.translations.keyEvents}</div>
      <div class="${widgetName}__wrapper">
        ${periodHeader(settings.translations.kickOff)}
        ${repeat(rows,
        row => row.id,
        row => row.template
      )}
      </div>
    `, context);
  };

  /**
   * Gets match key events using AJAX
   */
  function getMatchKeyEvents() {
    if (!initialized) {
      return;
    }

    if (ajaxInstance) {
      ajaxInstance.abort();
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context]);
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);

    ajaxInstance = $.ajax({
      url: settings.url,
      data: asyncRequestParams,
    })
      .done(response => {
        if (response.status === 'success') {
          renderList(response.data);
          scheduleUpdate(response.data);
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError(`Error: ${xhr}, ${status}, ${error}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
        }
      })
      .always(() => {
        ajaxInstance = null;
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  /* eslint-disable jsdoc/check-param-names */
  /**
   * Schedule next update
   * @param {number} matchStartTime - match start time in ms
   * @param {string} matchState - match state
   */
  function scheduleUpdate({ matchStartTime, matchState }) {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    if (matchState === 'postGame') {
      return;
    }

    const startRefreshAt = matchStartTime - TIME_TO_START_AUTOREFRESH;
    let timeOut = startRefreshAt - Date.now();

    timeOut = timeOut > 0 ? timeOut : REFRESH_DATA_INTERVAL_TIME;

    updateTimeout = setTimeout(getMatchKeyEvents, Math.min(ONE_DAY, timeOut));
  }
  /* eslint-enable jsdoc/check-param-names */

  if (!initialized) {
    const initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getMatchKeyEvents();
      pubsub.unsubscribe(initSubscription);
    });
  } else {
    renderList(settings);
    scheduleUpdate(settings);
  }
}
