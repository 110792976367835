import 'widgets/game-stats/style.scss';
import 'views/partials/widget-base/style.scss';

import GameStats from './widget.ts';

/**
 * Game stats widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - widget settings
 */
export default function (context, settings) {
  const widget = new GameStats(context, settings);

  widget.init();
}
