import 'widgets/starting-eleven/style.scss';
import pubsub from 'pubsub.js';
import optaWidget from 'components/opta-widget/widget';
import { breakpoint } from 'components/vendor/perform/responsive';
import { show as showLoader, hide as hideLoader } from 'components/loader';

/**
 * Opta starting eleven widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings) {
  const $context = $(context);
  const eventNamespace = 'team-switch';
  showLoader(context);

  /**
   *  Increase circle sizes
   * @param {number} proportion - proportion used for scale circle
   */
  function increaseCircleSizes(proportion) {
    const $circles = $context.find('circle.Opta-Away, circle.Opta-Home');
    $circles.each(function circlesIterator() {
      const $this = $(this);
      const cx = $this.attr('cx');
      const cy = $this.attr('cy');

      $this.attr('transform',
       `translate(-${cx * (proportion / 10)} -${cy * (proportion / 10)})
        scale(${1 + (proportion / 10)} ${1 + (proportion / 10)})`);
    });
  }

  optaWidget(context, {
    widgetId: 'match-day-live',
    onDraw: () => {
      const { awayTeamUrl, homeTeamUrl } = settings.contestants;

      $context.find('.Opta-Home .Opta-Image-Team-Medium')
      .wrap(`<a class="widget-starting-eleven-team-link"
      data-jsblank="true" href="${homeTeamUrl}"></a>`);

      $context.find('.Opta-Away .Opta-Image-Team-Medium')
      .wrap(`<a class="widget-starting-eleven-team-link"
      data-jsblank="true" href="${awayTeamUrl}"></a>`);

      if (breakpoint('to', '580')) {
        increaseCircleSizes(7);
      } else if (breakpoint('from', '580') && breakpoint('to', '740')) {
        increaseCircleSizes(2);
      }

      hideLoader(context);
      pubsub.publish(`${eventNamespace}/display-dropdown`, []);
    },
    onError: () => {
      hideLoader(context);
      $context.remove();
      pubsub.publish(`${eventNamespace}/show-match-stats`, []);
    },
    params: {
      load_when_visible: true,
    },
  });
}
