import { debounce } from 'components/vendor/perform/utils';
import { warn, info } from 'components/vendor/perform/core';
import { show as showLoader, hide as hideLoader } from 'components/loader';
import { pushEvent } from 'components/ga';
import 'widgets/live-action/style.scss';

require('promise-polyfill/src/polyfill');

let ready;
const readyPromise = new Promise((resolve) => {
  ready = resolve;
});

window.mksh = window.mksh || {};
window.mksh.liveAction = {
  onReady: (callback) => {
    readyPromise.then(callback);
  },
};

/**
 * Opta live action widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - widget settings
 * @param {string} settings.matchId - match id
 * @param {string} settings.outletAuthToken - outlet token
 * @param {string} settings.homeTeamName - home team name
 * @param {string} settings.awayTeamName - away team name
 * @param {string} settings.getTokenUrl - token url
 */
export default function (context, {
  matchId,
  outletAuthToken,
  homeTeamName,
  awayTeamName,
  getTokenUrl,
}) {
  if (getTokenUrl == '') {
    return;
  }


  const $context = $(context);
  const classWidget = 'widget-live-action';
  const classContent = `${classWidget}__content`;
  const selectorPlaceholder = `.${classWidget}__placeholder`;
  const selectorContent = `.${classContent}`;
  const $content = $(selectorContent);
  let token = '';
  let lastWidth = $context.innerWidth();

  /**
   * Load CSB script
   * @returns {Promise} - script load promise
   */
  function loadCSBWidget() {
    return new Promise((resolve, reject) => {
      if (token) {
        const iframe = document.createElement('iframe');
        iframe.src = '//visualisation.performgroup.com/csb/index.html?token=' + token + '&homeTeam=' + homeTeamName + '&awayTeam=' + awayTeamName + '&rbId=' + matchId + '&width=' + lastWidth + '&lang=tr&wbeventid=0&cssdiff=//arsiv.mackolik.com/matchcast/css_diff.css';
        iframe.setAttribute('scrolling', 'no');
        iframe.setAttribute('frameborder', '0');
        iframe.onload = resolve;
        iframe.onerror = reject;
        $content.get(0).appendChild(iframe);
      }
    });
  }

  /**
   * Render CSB Widget
   */
  function renderCSBWidget() {
    showLoader($content);
    $content.html('');

    lastWidth = lastWidth || $context.innerWidth();

    loadCSBWidget().finally(() => {
      hideLoader($content);
    });
  }

  $context.find(selectorPlaceholder).one('click', function onPlaceholderClick() {
    getToken().then(() => {
      $(window).on('resize-x', debounce(() => {
        const newWidth = $context.innerWidth();
        if (newWidth !== lastWidth) {
          lastWidth = newWidth;
          renderCSBWidget();
        }
      }, 500));
      $content.addClass(`${classContent}--active`);
      $(this).remove();
      renderCSBWidget();
  
      pushEvent({
        category: 'CSBPlay',
        action: 'CSBPlayClick',
        label: 'CSBPlay',
      });
    });
  });

  function getToken () {
    return fetch(getTokenUrl)
    .then((response) => new Promise((resolve, reject) => {
      if (!response.ok) {
        reject({
          code: response.status,
          message: 'Invalid response',
        });
      }

      resolve(response.text());
    }))
    .then((response) => new Promise((resolve, reject) => {
      const error = {
        code: response,
        message: '',
      };

      switch (response) {
        case '101':
        case '102':
        case '104':
        case '105':
          error.message = 'Authentication error';
          break;
        case '103':
          error.message = 'Invalid Match ID';
          break;
        case '111':
          error.message = 'No matches available for team';
          break;
        default:
          token = response;
          resolve(response);
          return;
      }

      reject(error);
    }))
    .then(() => {
      info('widget/live-action: can render CSB');
      ready(true);
    })
    .catch((error) => {
      if (error && error.code && error.message) {
        warn(`widget/live-action: ${error.message} (code: ${error.code})`);
      } else {
        warn('widget/live-action: Unknown error');
      }
      context.parentNode.removeChild(context);
      ready(false);
    });
  }

  $context.removeClass(`${classWidget}--hidden`);
}
