import 'widgets/match-stats/style.scss';
import globalPubsub from 'pubsub.js';
import moment from 'moment';
import { error as logError } from 'components/vendor/perform/core';
import { init as applyLazyLoader } from 'components/vendor/perform/lazy-loader';
import { pubsubNamespace } from 'components/vendor/perform/utils';

const SEC_IN_MILLIS = 1000;
const REFRESH_DATA_INTERVAL_TIME = 60 * SEC_IN_MILLIS;
const TIME_TO_START_AUTOREFRESH = 30 * 60 * SEC_IN_MILLIS;

/**
 * match stats widget
 * @param {HTMLElement} context - html element
 * @param {Object} settings - settings
 */
export default function (context, settings) {
  const $context = $(context);
  const pubsub = $context.data('pubsub') || globalPubsub;
  const eventNamespace = pubsubNamespace(context);
  const asyncRequestParams = settings.asyncRequestParams;
  const classWidget = 'widget-match-stats';
  const selectorWidget = `.${classWidget}`;
  const selectorWrapper = `${selectorWidget}__wrapper`;
  const selectorMatchStatus = 'data-match-status';
  const eventsMap = {};
  let initialized = settings.initialized;
  let ajaxInstance;
  let autoRefreshInterval;

  /**
   * Gets match stats using AJAX
   */
  function getMatchStats() {
    if (!initialized) {
      return;
    }

    if (ajaxInstance) {
      ajaxInstance.abort();
      pubsub.publish(`${eventNamespace}/ajax/cancel`, [context]);
    }

    pubsub.publish(`${eventNamespace}/ajax/before`, [context]);

    ajaxInstance = $.ajax({
      url: settings.url,
      data: asyncRequestParams,
    })
      .done(response => {
        if (response.status === 'success') {
          updateKeyEvents(response.data.stats);
          stopAutoRefresh(response.data.staus);
          pubsub.publish(`${eventNamespace}/ajax/complete`, [context, response.data]);
        } else {
          logError(`Error: ${response.data}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, response.data]);
        }
      })
      .fail((xhr, status, error) => {
        if (status !== 'abort') {
          logError(`Error: ${xhr}, ${status}, ${error}`);
          pubsub.publish(`${eventNamespace}/ajax/fail`, [context, { xhr, status, error }]);
        }
      })
      .always(() => {
        ajaxInstance = null;
        pubsub.publish(`${eventNamespace}/ajax/after`, [context]);
      });
  }

  /**
   * Checks if status is post game (canceled and played)
   * @param {string} status - status to be checked
   */
  function isPostGame(status) {
    return status === 'postGame';
  }

  /**
   * Checks status of new data and stops refreshing when status is appropriate
   * @param {string} status - match status
   */
  function stopAutoRefresh(status) {
    if (isPostGame(status)) {
      clearInterval(autoRefreshInterval);
    }
  }

  /**
   * Start auto refresh of match stats
   */
  function startUpdate() {
    const $wrapper = $context.find(selectorWrapper);
    const matchStatus = $wrapper.attr(selectorMatchStatus);

    if (isPostGame(matchStatus)) {
      return;
    }

    const matchStartTimestamp = $wrapper.attr('data-utc') * SEC_IN_MILLIS;
    const startRefreshAt = matchStartTimestamp - TIME_TO_START_AUTOREFRESH;
    const now = moment().utc().valueOf();
    const timeOut = startRefreshAt - now;

    if (!autoRefreshInterval) {
      if (timeOut > 0) {
        setTimeout(() => {
          autoRefreshInterval = setInterval(getMatchStats, REFRESH_DATA_INTERVAL_TIME);
        }, timeOut);
      } else {
        autoRefreshInterval = setInterval(getMatchStats, REFRESH_DATA_INTERVAL_TIME);
      }
    }
  }

  if (!initialized) {
    const initSubscription = pubsub.subscribe(`${eventNamespace}/init`, eventContext => {
      if (context !== eventContext) {
        return;
      }

      initialized = true;
      getMatchStats();
      pubsub.unsubscribe(initSubscription);
    });

    const completeSubscription = pubsub.subscribe(
      `${eventNamespace}/ajax/complete`,
      eventContext => {
        if (context !== eventContext) {
          return;
        }

        startUpdate();
        pubsub.unsubscribe(completeSubscription);
      }
    );
  } else {
    startUpdate();
  }

  /**
   * Update event containers
   * @param {Array} updateArray - array with key events to update
   */
  function updateEventContainers(updateArray) {
    updateArray.forEach(element => {
      const eventsContainer = context.querySelector(`
          ${selectorWidget}__row[data-person-id='${element.personId}'] 
          ${selectorWidget}__events-container 
      `);
      eventsContainer.innerHTML = '';

      element.events.forEach(event => {
        const eventContainer = document
          .createElement('span');

        eventContainer.classList.add(
          `${classWidget}__icon`,
          `${classWidget}__icon--${event.name}`
        );

        switch (event.name) {
          case 'sub-off':
          case 'sub-on':
            eventContainer.innerHTML = `
              <span class="widget-match-stats__event-time">
                ${event.timeMin}'
              </span>
            `;
            break;
          case 'goal':
            eventContainer.innerHTML = `
              ${event.count > 1 ?
              `<span class="widget-match-stats__event-time">
                  x${event.count}
              </span>`
              : ''}            
            `;
            break;
          default:
            break;
        }

        eventsContainer.appendChild(eventContainer);
      });
    });
  }

  /**
   * Update key events
   * @param {Object} data - key events data
   */
  function updateKeyEvents(data) {
    if (settings.asyncRequestParams.ajaxViewName === 'manager') {
      return;
    }

    if (!eventsMap) {
      $context.find(`${selectorWidget}__events-container`).each((item) => {
        const $container = $(item);
        const personId = $container.closest('[data-person-id]').data('personId');

        eventsMap[personId] = $container.find(`${selectorWidget}__icon`).length;
      });
    }

    const dataToUpdate = data.home.concat(data.away)
      .filter(item => {
        const eventsCount = item.events ? item.events.length : 0;

        if (eventsCount > 0 && (
          !eventsMap[item.personId] || eventsMap[item.personId] !== eventsCount)
        ) {
          eventsMap[item.personId] = eventsCount;
          return true;
        }

        return false;
      });

    if (dataToUpdate.length) {
      updateEventContainers(dataToUpdate);
    }
  }

  applyLazyLoader(context);
}
