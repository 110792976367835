import 'widgets/sidelined-players/style.scss';
import 'views/partials/widget-base/style.scss';
import { delegateEvent, closest, forEachElement } from 'components/utils/script.js-next';

/**
 * Player Career Summaries module
 * @param {Object} context - context object
 */
export default function (context) {
  const widgetClass = 'widget-sidelined-players';
  const classInfoRow = `${widgetClass}__details`;
  const selectorInfoRow = `.${classInfoRow}`;
  const infoRows = context.querySelectorAll(selectorInfoRow);
  const selectorItem = `.${widgetClass}__item`;
  const classExpander = `${widgetClass}__expander`;
  const selectorExpander = `.${classExpander}`;
  const classExpanderClicked = `${classExpander}--clicked`;
  const rowHeights = new Array(infoRows.length);

  delegateEvent(context, 'click', selectorExpander, function addChosenClass() {
    const sidelinedRow = closest(event.target, selectorItem);
    const sidelinedInfoRow = sidelinedRow.querySelector(selectorInfoRow);
    const index = Array.prototype.indexOf.call(infoRows, sidelinedInfoRow);

    this.classList.toggle(classExpanderClicked);

    if (this.classList.contains(classExpanderClicked)) {
      sidelinedInfoRow.style.height = `${rowHeights[index]}px`;
    } else {
      sidelinedInfoRow.style.height = '';
    }
  });

  /**
   * Get info about row heights
   */
  function getInfoRowHeights() {
    forEachElement(infoRows, (element, index) => {
      element.style.height = 'auto';
      const height = element.offsetHeight;
      rowHeights[index] = height;
      element.style.height = '';
    });
  }

  $(window).on('resize-x', getInfoRowHeights);

  getInfoRowHeights();
}
