import 'widgets/previous-squad/style.scss';
import { init as applyLazyLoader } from 'components/vendor/perform/lazy-loader';

/**
 * Opta starting eleven widget
 * @param {HTMLElement} context - html element
 */
export default function (context) {
  applyLazyLoader(context);
}
